import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import { FAQJsonLd } from 'gatsby-plugin-next-seo'
import { ArticleJsonLd } from 'gatsby-plugin-next-seo';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql, StaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types"
import casinolistStyle from "../components/Casinolist.module.css"

class Kasinot extends React.Component {
  componentDidMount() {
    var links = document.links;
    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
        if (links[i].hostname != window.location.hostname) {
            links[i].target = '_blank';
            links[i].rel = 'noopener';
        }
    }
		var talletus = document.getElementsByClassName("talletus");
		var o;
		for (o = 0; o < talletus.length; o++) {
			var trustly = "<img src='/images/maksu/trustly.webp' title='Trustly' alt='trustly' width='75' height='50' class='lazyload'/>"
			var perinteiset = "<img src='/images/maksu/perinteiset.webp' title='Perinteiset maksutavat' alt='perinteiset' width='75' height='50' class='lazyload'/>"
			var brite = "<img src='/images/maksu/brite.webp' alt='brite' title='Brite' width='75' height='50' class='lazyload'/>"
			var zimpler = "<img src='/images/maksu/zimpler.webp' title='Zimpler' alt='zimpler' width='75' height='50' class='lazyload'/>"
			var str = talletus[o].innerHTML;

			var res = str.replace("Trustly", trustly).replace("Brite", brite).replace("Perinteiset", perinteiset).replace("Zimpler", zimpler);
			talletus[o].innerHTML = res;
		}
		
  }
  render() {
  return (
    <StaticQuery
      query = {graphql`
      query {
        contentfulMoneysivujenOsiot(slug: {eq: "kasinot"}) {
          slug
          sivu
          ylosanTeksti {
            json
          }
          kasinolista {
            kasinonNimi
            kasinonListanimi
            lause1
            ilmaiskierrokset
            rahansiirto
            logonTiedostonimi
            afflink
            kasinonLisatiedot {
              json
              }
          }
          alaosanTeksti {
            json
          }
          alaosanTeksti2 {
            json
          }
        }
      }
      `}
      render={data => (
    <Layout>
      <GatsbySeo
      title='Viron kasinot | Kaikki Maksu- ja Tolliamet (EMTA) nettikasinot'
      description='Pelaa huoleti virolaisella kasinolla. Viron Vero- ja Tullihallituksen lisensoimat nettikasinot ovat luotettavia, turvallisia ja vahvasti valvottuja kasinoita. Ja mikä parasta, suomalaisille pelaajille verovapaita.'
   
   />
        <FAQJsonLd
            questions={[
              { question: 'Miten Viron lisenssi eroaa Maltan lisenssistä?', answer: 'Suomalaisen pelaajan näkökulmasta: ei mitenkään. Kuten Viron tarjoama lisenssi, myös Maltan lisenssi eurooppalaisena tarjoaa suomalaisille pelaajille verottomat voitot sekä äärimmäisen turvallisen peliympäristön. Käytännössä suomalaisilla on siis kaksi lisenssiä, joista kannattaa valita: maltalaisen MGA:n ja Viron lisenssit.' },
              { question: 'Mitä kasinoyritykseltä vaaditaan Viron pelilisenssin saamiseksi? ', answer: 'Lisenssi vaatii esimerkiksi tietoja nettikasinon liiketoiminnasta ja sitä, että pelaajalle kerrotaan aina tiettyjä asioita peliyrityksestä sekä pelaamisen vaaroista, kustannuksista ja säännöistä. Luonnollisesti pelaajan tulee tunnistautua ja alaikäiset eivät saa pelata kasinoilla.' },
              { question: 'Mitkä nettikasinot toimivat Viron lisenssin alaisuudessa?', answer: 'Lista on todella pitkä. Löydät täydellisen luettelon sivuiltamme. Muutamia mainitaksemme: Chanz, Betsafe, Bet365, Boost Casino, Slot.io sekä maailman ensimmäinen rekisteröintivapaa toimija, Ninja Casino.' },
              { question: 'Mitä maksutapoja virolaisilla kasinoilla on käytössä?', answer: 'Maksutapojen valikoimasta löydät kaikki suomalaisten suosikit. Näihin kuuluvat muun muassa Trustly ja Zimpler. Edellämainittuja vaihtoehtoja käyttämällä ei tarvitse täytellä rekisteröitymislomakkeita ja rahaliikenne hoituu näppärästi verkkopankkitunnuksilla.' },
              { question: 'Löytyykö Virosta ilman rekisteröitymistä toimivia nettikasinoita?', answer: 'Kyllä, myös Viron nettikasinot tarjoavat tämän mahdollisuuden. Niin kutsutut pikakasinot ovat olleet jo pitkään toivottuja ja suosittuja kasinoita helppoutensa vuoksi. Virolaiset nettikasinosivut tarjoavat myös nopeita voittojen nostoa asiakkailleen.' },
              { question: 'Kuinka henkilöllisyyden vahvistus toimii virolaisella kasinolla?', answer: 'Positiivista virolaisissa kasinoissa on, että dokumentteja oman henkilöllisyyden vahvistamiseksi ei yleensä tarvitse alkaa lähettelemään. Pelitili vahvistetaan useimmiten automaattisesti talletuksen yhteydessä omilla verkkopankkitunnuksilla.' },
            ]}
          />
          <ArticleJsonLd
      url='https://www.virokasino.com/'
      headline='Viron kasinot | Kaikki Maksu- ja Tolliamet (EMTA) nettikasinot'
      images={[
        'https://www.virokasino.com/images/viro-kasino.webp'
      ]}
      description='Pelaa huoleti virolaisella kasinolla. Viron Vero- ja Tullihallituksen lisensoimat nettikasinot ovat luotettavia, turvallisia ja vahvasti valvottuja kasinoita. Ja mikä parasta, suomalaisille pelaajille verovapaita.'
      publisherName='virokasino.com'
      publisherLogo='https://www.virokasino.com/images/viro-kasino.webp'
      overrides={{
        '@type': 'techArticle', 
        'profiencyLevel':'Expert'
      }}
    />
        <Container>
        <div class="suodatin">
          <p><b>Virolaiset nettikasinot - mistä on kyse?</b></p>
        <p><i>Pelaajat törmäävät yhä useammin Viron Maksu- ja Tolliametin (EMTA) logoon. Mikäli kasinosivuilla on kyseinen logo, se tarkoittaa, että pelisivuston toiminnasta vastaa Viron rahapelilainsäädäntö. Nämä säännöt koskettavat myös suomalaisia, jotka pelaavat kyseisellä kasinolla.</i></p>
        <p>Monella saattaa syntyä epäilyksiä siitä, että ovatko Viron nettikasinot luotettavia tai kannattaako niillä ylipäätään pelata. Nämä epäilykset voivat johtua siitä, että virolaiset kasinolisenssit ovat verrattain uusia nettikasinomarkkinoilla. Pelaaja voi kuitenkin olla huoleti virolaisella kasinolla pelatessaan, sillä Viron vero- ja tullihallituksen lisensoimat kasinot ovat luotettavia, turvallisia ja vahvasti valvottuja kasinoita. Ja mikä parasta, suomalaisille pelaajille verovapaita.</p>
                    </div>
                    <div>
          <p><center>&#127466;&#127466; <b>LISTA: Kaikki Viro Kasinot</b> &#127466;&#127466;</center></p>
                    </div>
        <table>
          <tbody>
            {data.contentfulMoneysivujenOsiot.kasinolista.map(({logonTiedostonimi,kasinonListanimi,lause1,ilmaiskierrokset,afflink,kasinonLisatiedot,rahansiirto,}) => (
            <tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={`/images/a/${logonTiedostonimi}`} alt={kasinonListanimi} className="lazyload" width="120" height="50"/>
									<div className={casinolistStyle.name}>
                  <a href={afflink} target="_blank"><span className={casinolistStyle.title}><b>{kasinonListanimi}</b></span></a>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<p>&#128280; {lause1}</p>
                        <p>&#128280; {ilmaiskierrokset}</p>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
                    <a className={casinolistStyle.btn} href={afflink} target="_blank">Kokeile &#127922;</a>
                      <div className="talletus">{rahansiirto}</div>
                  	</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>

								<div className={casinolistStyle.casinoalaosa}>
                {documentToReactComponents(kasinonLisatiedot.json)}
								</div>
							</td>
						</tr>
            ))}
          </tbody>
        </table>
        <div className="ylaosa suodatin3"><PageTitle>{data.contentfulMoneysivujenOsiot.sivu}</PageTitle>{documentToReactComponents(data.contentfulMoneysivujenOsiot.ylosanTeksti.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
        })}</div>
        
        <div className="alaosa suodatin3">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
      <div className="alaosa2 suodatin3">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti2.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
        <div class="suodatin3">
        <center><h3>UKK - Näitä kysytään usein Viron kasinoista:</h3></center>
                <div>
                  <div><h4>&#128204; Miten Viron lisenssi eroaa Maltan lisenssistä? </h4></div>
                  <p>Suomalaisen pelaajan näkökulmasta: ei mitenkään. Kuten Viron tarjoama lisenssi, myös Maltan lisenssi eurooppalaisena tarjoaa suomalaisille pelaajille verottomat voitot sekä äärimmäisen turvallisen peliympäristön. Käytännössä suomalaisilla on siis kaksi lisenssiä, joista kannattaa valita: maltalaisen MGA:n ja Viron lisenssit.</p>
                </div>
                <div>
                  <div><h4>&#128204; Mitä kasinoyritykseltä vaaditaan Viron pelilisenssin saamiseksi? </h4></div>
                  <p>Lisenssi vaatii esimerkiksi tietoja nettikasinon liiketoiminnasta ja sitä, että pelaajalle kerrotaan aina tiettyjä asioita peliyrityksestä sekä pelaamisen vaaroista, kustannuksista ja säännöistä. Luonnollisesti pelaajan tulee tunnistautua ja alaikäiset eivät saa pelata kasinoilla.</p>
                </div>                
                <div>
                  <div><h4>&#128204; Mitkä nettikasinot toimivat Viron lisenssin alaisuudessa?</h4></div>
                  <p>Lista on todella pitkä. Löydät täydellisen luettelon sivuiltamme. Muutamia mainitaksemme: Chanz, Betsafe, Bet365, Boost Casino, Slot.io sekä maailman ensimmäinen rekisteröintivapaa toimija, Ninja Casino.</p>
                </div>     
                <div>
                  <div><h4>&#128204; Mitä maksutapoja virolaisilla kasinoilla on käytössä? </h4></div>
                  <p>Maksutapojen valikoimasta löydät kaikki suomalaisten suosikit. Näihin kuuluvat muun muassa Trustly ja Zimpler. Edellämainittuja vaihtoehtoja käyttämällä ei tarvitse täytellä rekisteröitymislomakkeita ja rahaliikenne hoituu näppärästi verkkopankkitunnuksilla.</p>
                </div> 
                <div>
                  <div><h4>&#128204; Löytyykö Virosta ilman rekisteröitymistä toimivia nettikasinoita? </h4></div>
                  <p>Kyllä, myös Viron nettikasinot tarjoavat tämän mahdollisuuden. Niin kutsutut pikakasinot ovat olleet jo pitkään toivottuja ja suosittuja kasinoita helppoutensa vuoksi. Virolaiset nettikasinosivut tarjoavat myös nopeita voittojen nostoa asiakkailleen.</p>
                </div> 
                <div>
                  <div><h4>&#128204; Kuinka henkilöllisyyden vahvistus toimii virolaisella kasinolla? </h4></div>
                  <p>Positiivista virolaisissa kasinoissa on, että dokumentteja oman henkilöllisyyden vahvistamiseksi ei yleensä tarvitse alkaa lähettelemään. Pelitili vahvistetaan useimmiten automaattisesti talletuksen yhteydessä omilla verkkopankkitunnuksilla. </p>
                </div>            
              </div>
      
      </Container>
    </Layout>
    )}
    />
  )
  }
}

export default Kasinot

